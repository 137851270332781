import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components/macro'
import BlogPostPreview from '../components/BlogPostPreview'
import Layout from '../components/Layout'

export default function Blog({
  data: {
    allMarkdownRemark: { edges },
  },
}) {
  const BlogPosts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <BlogPostPreview key={edge.node.id} post={edge.node} />)

  return (
    <Layout pageTitle="Blog">
      <main>
        <h2>Blog - Ideation by Rebecca</h2>
        <p>From time to time, I'll take you with me on my journey.</p>
        <BlogPostsStyled>{BlogPosts}</BlogPostsStyled>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query blogQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            tags
            title
            thumbnail
          }
        }
      }
    }
  }
`

const BlogPostsStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1.5em;
  margin: 0 auto;
`
