import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components/macro'

export default function BlogPostPreview({ post }) {
  return (
    <BlogPostPreviewStyled>
      <Link to={post.frontmatter.path}>
        {!!post.frontmatter.thumbnail && (
          <ThumbnailStyled
            src={post.frontmatter.thumbnail}
            alt={post.frontmatter.title + '- Featured Shot'}
          />
        )}
      </Link>
      <header>
        <DateStyled>{post.frontmatter.date}</DateStyled>
        <TagsStyled>{post.frontmatter.tags}</TagsStyled>
        <h2>
          <LinkStyled to={post.frontmatter.path}>
            {post.frontmatter.title}
          </LinkStyled>
        </h2>
      </header>
      <ExcerptStyled>{post.excerpt}</ExcerptStyled>
    </BlogPostPreviewStyled>
  )
}

const BlogPostPreviewStyled = styled.article`
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: space-evenly;
  font-size: 1.4rem;
  padding: 12px;
  text-align: left;
  overflow: hidden;
  width: 100%;
`
const DateStyled = styled.p`
  font-size: 1rem;
  margin-bottom: 12px;
`
const ExcerptStyled = styled.p`
  height: 1fr;
`
const LinkStyled = styled(Link)`
  font-size: 2.2rem;
  text-decoration: none;
`
const TagsStyled = styled.p`
  font-style: italic;
  font-size: 1rem;
  margin-bottom: 12px;
`
const ThumbnailStyled = styled.img`
  height: 100%;
  max-height: 300px;
  object-fit: cover;
  padding-bottom: 12px;
  width: 100%;
`
